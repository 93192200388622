import React from "react"
import useTranslations from "../../../utils/useTranslations"
import FeaturesList from "../../../components/featuresList"
import LocalizedLink from "../../../components/localizedLink"
import ComparisonPanels from "../../../components/comparisonPanels"

import heroImgWebp from "../../../images/homepage/screen-web.webp"
import heroImg from "../../../images/homepage/screen-web.png"
import heroImgWebp2x from "../../../images/homepage/screen-web@2x.webp"
import heroImg2x from "../../../images/homepage/screen-web@2x.png"

import Button from "../../../components/button"
import { CompatibilityContext } from "../../../components/compatibilityContext";
import classicLogo from "../../../images/logos/classic-black.png"

import classicScreenshotWebp from "../../../images/homepage/screen-classic-mac-laptop.webp"
import classicScreenshot from "../../../images/homepage/screen-classic-mac-laptop.png"
import classicScreenshotWebp2x from "../../../images/homepage/screen-classic-mac-laptop@2x.webp"
import classicScreenshot2x from "../../../images/homepage/screen-classic-mac-laptop@2x.png"

import Logo from "../../../components/logo"
import warning from "../../../images/icons/warning.svg"
import backgrounds from "../../../components/backgrounds"

const heroImgAlt = "Download BitTorrent Web for your Mac computer"

const webLink = "/products/mac/bittorrent-web-compare"
const classicLink = "/downloads/complete/classic"

class BittorrentWebMac extends React.Component {

  handleClassicClick = (e, link) => {
    this.context(e, 'downloading', "Classic", ["Osx"], link)

  }

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { pathname }  = this.props.location

    return (
      <>
        <div className="top-section-container p-0 pb-md-5 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>

          <div className="container text-dark py-2">

            <div className="row text-center mt-4 mt-sm-5">
              <div className="col-md-6 py-md-3 order-md-1 px-md-0">
              	<Logo color="black" tag="h1" productName="Web for Mac" className="mb-4"/>
              	
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-block d-md-none mb-4"><a id="products-mac-btweb-free-bt-now-mobile" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>


                <div className="text-size-24 text-spacing-1 font-weight-normal">{t(`Online torrent downloader and player.`)}</div>
                <ul className="product-hero-list mt-3">
                	<li>
                		{t(`Quickly play torrent files and magnet links`)}
                	</li>
                	<li>
                		{t(`Download torrents on Mac from your favorite browser`)}
                	</li>
                	<li>
                		{t(`Supporting MacOS Catalina and above`)}
                	</li>
                </ul>
                <div>
                    <LocalizedLink to={webLink} >
                      <Button id="products-btweb-for-mac" className=" btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">
                        {t(`Free Download`)}
                      </Button>
                    </LocalizedLink>
                </div>
                <a href={`${pathname}#mac-classic`} id="products-btweb-for-mac-classic-anchor" className="d-block text-dark">{t(`Looking for BitTorrent Classic for Mac?`)}</a>
              </div>
              <div className="col-md-6 order-md-2 d-flex justify-content-center align-items-center">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block hero-img"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block hero-img"></img>
                  <div style={{fontSize: '10px'}} className="d-none d-md-block"><a id="products-mac-btweb-free-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
                </picture>

              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid overflow-hidden position-relative">
          <div className="bg-text-container bg-text-gray">
            {t(`FeaturesHeader`)}
          </div>
          <div className="container mt-5 z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`FeaturesHeader`)}</p>

            <p className="text-center mb-5 text-size-24" >
            	BitTorrent Web {t(`Online Torrent Client`)} {t(`Features`)}
            </p>
            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden background-noise-dark" style={backgrounds.darkCorners}>
          <div className="container my-5 text-center z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3 text-dark">{t(`Compare`)}</p>

            <h2 className="text-center mb-5 text-size-24 font-weight-normal text-dark">
                {t(`Compare BitTorrent Web Versions`)}
            </h2>
          </div>
          <ComparisonPanels pageContext={{ "text": text, "pageName":pageName }}/>
        </div>

        <div className="container-fluid py-5" style={backgrounds.lightBg}>
  
    		<div className="container pb-md-3">
    			<div className="row text-center mt-4 mt-sm-5" id="mac-classic">
              <div className="col-md-6 mt-md-0 order-md-2 px-md-0">
                <Logo color="black" tag="h2" productName="Classic" className="mb-4"/>
                {/*<img src={classicScreenshot} alt={heroImgAlt} className="img-fluid d-inline-block d-md-none mb-4 align-self-center"></img>*/}

                <picture>
                  <source srcSet={`${classicScreenshotWebp} 1x, ${classicScreenshotWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${classicScreenshot} 1x, ${classicScreenshot2x} 2x`} src={classicScreenshot} alt={heroImgAlt} className="img-fluid d-inline-block d-md-none mb-4 align-self-center"></img>
                </picture>

                <div className="text-size-24 text-spacing-1 font-weight-normal">{t(`The original Mac torrent client for the desktop.`)}</div>
                <ul className="mt-2 product-hero-list text-dark">
                	<li>
                		{t(`Mac torrent client for fast, bulk downloading.`)}
                	</li>
                	<li>
                		{t(`Advanced customization.`)}
                	</li>
                	<li>
                		{t(`Small setup file.`)}
                	</li>
                </ul>
                <div>
                  <Button onClick={(e) => {this.handleClassicClick(e, classicLink)}} href={classicLink} id="products-classic-for-mac" className=" btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">{t(`Free Download`)}</Button>
                </div>
                  <div>
                    <img src={warning} alt="Warning Icon" style={{ height: `20px` }} className="warning-icon mr-1 d-inline pb-1"/>
                    <p className="d-inline text-left text-size-14"> {t(`Not compatible with MAC OS Catalina Version 10.15 and above`)}</p>
                  </div>
              </div>
              <div className="col-md-6 mb-5 order-md-1 d-none d-md-block">
                {/*<img src={classicScreenshot} alt="BitTorrent Classic in-client" className="img-fluid d-none d-md-block"></img>*/}

                <picture>
                  <source srcSet={`${classicScreenshotWebp} 1x, ${classicScreenshotWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${classicScreenshot} 1x, ${classicScreenshot2x} 2x`} src={classicScreenshot} alt="BitTorrent Classic in-client" className="img-fluid d-none d-md-block"></img>
                </picture>
                
              </div>
            </div>
          </div>
        </div>

      </>
    )

  }
}

BittorrentWebMac.contextType = CompatibilityContext;

export default BittorrentWebMac